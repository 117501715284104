.supplier-interface .optionBlock{
    padding: 20px;
}
.day-block > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.day-block{
    margin-bottom: 15px;
}
.day-block-wrapper{
    margin-bottom: 20px;
}
.sub-title{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
}
.by{
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: 500;
    font-size: 13px;
    flex-basis: 100%;
}
.by > span{
    font-size: 13px;
    font-weight: normal;
    color: #797979;
    margin-left: 5px;
}
.by a {
    color:#2D3340 !important;
}
.diet{
    font-size: 13px;
    font-weight: normal;
    color: #797979;
}
.small-btn{
    font-size: 13px !important;
    padding: 5px 15px !important;
}
.blackButton.small-btn.gray{
    background: #888888;
}
.blackButton.small-btn.blue{
    background: #2D3340;
}
.justify-between{
    display: flex;
    justify-content: space-between;
    width:100%;
    align-items: center;
    margin-bottom: 15px;
}
.title{
    margin-bottom: 0;
}
.scrollToTargetButton.to-top img{
    transform: rotate(90deg);
    width: 12px;
    text-align: center;
}
.scrollToTargetButton.to-down img{
    transform: rotate(-90deg);
    width: 12px;
    text-align: center;
}
.scrollToTargetButton{
    position: sticky;
    width: 40px;
    height: 40px;
    background: rgba(0,0,0,.2);
    border-radius: 50%;
    z-index: 1;
    bottom: 40px;
    left: 10px;
    align-items: center;
    justify-content: center;
    margin: auto 0px auto auto;
    cursor: pointer;
    display: none;
    opacity: 0;
    transition: .5s;
}
.scrollToTargetButton.show{
    display: flex;
    opacity: 1;
}
.optionBlock:last-child .line{
    border-bottom: 1px solid #797979;
}
.optionBlock{
    margin-bottom: 15px;
}

.optionBlock .line > span > span{
    max-width: inherit;
}

.popup-title{
    font-size: 24px;
    font-weight: 500;
}

.suggestion-row{
    display: flex;
}

.suggestion-row > div{
    flex-basis: 50%;
}

.flex-align-center{
    align-items: center;
    display: flex;
}
.supplier-interface .switcher-option{
    width: 240px;
    left: 0;
    right: 0;
    top: 15px;
    z-index: 1;
    margin: 0 auto;
    margin-bottom: 40px;
    box-shadow: 4px 4px 4px rgb(9 16 38 / 25%);
}




@media only screen and (max-width: 400px) {
    .supplier-interface .optionBlock{
        padding: 15px;
    }

    .booking-status-checkbox{
        padding: 5px 15px !important;
        margin: -15px -15px 15px !important;
    }

    .booking-status-checkbox .label{
        font-weight: 500;
        font-size: 16px;
    }

    .by .blackButton.small-btn.gray{
        background: #888;
        padding: 2px 5px !important;
        margin: 0 5px;
        line-height: 1;
        font-size: 12px !important;
    }


}