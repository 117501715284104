.chat{
    background: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.chat .top{
    display: flex;
    align-items: center;
    padding: 20px 16px;
    box-sizing: border-box;
}

.chat .top .dots-icon{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-right: 26px;
}
.chat .top .dots-icon div{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #2D3340;
    margin-bottom: 3px;
}
.chat .top .avatar{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    position: relative;
}


.chat .top .avatar img{
    max-width: initial;
    min-width: 100%;
    position: absolute;
}

.chat .top .avatar.supplier_avatar img{
    width: 200%;
    left: -50%;
}

.chat .messages{
    padding: 8px 16px;
    box-sizing: border-box;
    flex-grow: 1;
    max-height: calc(100vh - 220px);
    -webkit-box-shadow: 0px -19px 66px -19px rgba(34, 60, 80, 0.2) inset;
    -moz-box-shadow: 0px -19px 66px -19px rgba(34, 60, 80, 0.2) inset;
    box-shadow: 0px -19px 66px -19px rgba(34, 60, 80, 0.2) inset;
    overflow: auto;
}

.chat .message{
    margin-bottom: 16px;
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
}

.chat .message .date{
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    color: #797979;
    margin-bottom: 5px;
    flex-basis: 100%;
}

.chat .message .content{
    padding: 15px;
    box-sizing: border-box;
    color:white;
    font-size: 16px;
    max-width: 80%;
}

.chat .message.left .content{
    background: #2D3340;
    border-radius: 15px 15px 15px 0px;
}

.chat .message.right .content{
    background: #495468;
    border-radius: 15px 15px 0px 15px;
}
.chat .message.right{
     justify-content: flex-end;
}

.chat .message.right .date{
    text-align: right;
}

.chat .actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px;
    box-sizing: border-box;
    flex-wrap: wrap;
}
.chat .actions .wrapper-actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 100%;
}
.chat .actions .wrapper-actions label{
    margin: 0;
}
.chat .actions .MuiTextField-root{
    flex-grow: 1;
    margin-left: 16px;
}

body .chat .actions .MuiInputBase-root{
    height: auto !important;
    padding: 10px;
}

.sendIcon, .attachmentIcon{
    cursor: pointer;
}

video{
    width: 100%;
}

.chat-image{
    max-width: 100%;
    height: auto !important;
}
.chat-image img{
    max-width: 100% !important;
    height: auto;
}
