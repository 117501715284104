@import url(https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap);
body, html{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
html{
  background: #2D3340;
  height: 100%;
  overflow: hidden;
}
body, .MuiInputBase-root, .MuiMenuItem-root{
  font-family: 'Barlow', sans-serif !important;
}
.personal-info-menu .MuiMenuItem-root{
    font-weight: 600;
    font-size: 14px;
    border-bottom: 1px solid #797979;
    padding:0;
    margin: 0 15px;
}
.personal-info-menu .MuiMenuItem-root:last-child{
  border-bottom: 0;
}
#root, .fullscreen, #root > div{
  width: 100%;
  height: 100%;
}
.fullscreen{
  overflow-y: scroll;
  overflow-x: hidden;
}
.fullscreen.with-bottom-navigation{
  height: calc(100% - 56px);
}
.bg-main-color{
  background: #2D3340;
  color:#F7F7F7;
}
.bg-main-color * {
  color:#F7F7F7;
}
body .MuiInputBase-root{
  height: 45px;
}

.MuiFormHelperText-root.Mui-error{
  margin: 0;
  font-size: 11px;
}
.text-right{
  text-align: right;
  display: block;
}
.react-tel-input + .MuiFormHelperText-root.Mui-error{
  color:red;
}
.MuiFormControl-fullWidth{
  min-height: 65px;
}
.auto-height .MuiInputBase-root{
  height: auto !important;
}
.bg-main-color .MuiOutlinedInput-notchedOutline, .bg-main-color .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color:#F7F7F7 !important;
  border: 2px solid;
  background: rgba(247, 247, 247, 0.3);
}
body .react-tel-input .form-control{
  width: 100%;
  height: 45px;
  border-radius: 12px;
}

body .react-tel-input *{
  color: #2D3340
}
body .react-tel-input input{
  color: #2D3340;
  background: #F7F7F7 !important;
  border: 2px solid #2D3340 !important;
}

body .react-tel-input .flag-dropdown{
  border: 2px solid #2D3340 !important;
}

body .react-tel-input input::placeholder{
  color: #2D3340;
}

body .bg-main-color .react-tel-input input::placeholder{
  color: #F7F7F7;
}
body .bg-main-color .react-tel-input input::-ms-input-placeholder{
  color: #F7F7F7;
}

body .bg-main-color .react-tel-input input{
  color: #F7F7F7;
  background: #6a6f78 !important;
  border: 2px solid #F7F7F7 !important;
}

body .bg-main-color .react-tel-input .flag-dropdown{
  border: 2px solid #F7F7F7 !important;
}
body .react-tel-input .country-list .search-box{
  width: 85%;
  border-radius: 10px;
}
body .react-tel-input .flag-dropdown{
  border-radius: 12px 0 0 12px !important;
}

body .react-tel-input .selected-flag:hover, body  .react-tel-input .selected-flag:focus{
  border-radius: 12px 0 0 12px !important;
  background: none !important;
}

body .react-tel-input .flag-dropdown.open .selected-flag{
  border-radius: 12px 0 0 12px !important;
}
.MuiOutlinedInput-notchedOutline{
  border-radius: 12px !important;
  border: 2px solid #2D3340 !important;
}
.bg-main-color .MuiInputLabel-outlined {
  color:#F7F7F7 !important;
  font-size: 14px;
}
.bg-main-color .MuiOutlinedInput-input{
  color:#F7F7F7;
  font-size: 14px;
}

.bg-main-color .MuiOutlinedInput-input::placeholder{
  color: #F7F7F7 !important;
  opacity: 1;
}
.bg-main-color .MuiOutlinedInput-input:-ms-input-placeholder{
  color: #F7F7F7 !important;
  opacity: 1;
}
.bg-main-color .MuiOutlinedInput-input::-ms-input-placeholder{
  color: #F7F7F7 !important;
  opacity: 1;
}
.bg-main-color .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: #f44336 !important;
}

.bg-white{
  background: #fff;
  color: #2D3340;
  min-height: 100%;
}
.MuiBottomNavigation-root{
  background: #2D3340 !important;
}
.bg-main-color .MuiButton-label{
  color:#2D3340;
  text-transform: capitalize;
}
.bg-main-color .MuiButton-label font{
  color:#2D3340;
}
.MuiBottomNavigationAction-root{
  color: #c5c5c5 !important
}
.MuiBottomNavigationAction-root.Mui-selected{
  color:white !important;
}
.bg-main-color .MuiButtonBase-root.MuiButton-root{
  border-radius: 12px;
  font-size: 17px;
  min-width: 215px;
  background: #F7F7F7;
}

.searchHeader{
  display: flex;
  padding: 10px 15px;
  align-items: center;
  justify-content: space-between;
}
.customSelectCity{
  display: flex;
  align-items: center;
  padding: 3px 25px 3px 15px;
  position: relative;
  border-radius: 12px;
  width: 100%;
  max-width: 450px;
  height: 45px;
  box-sizing: border-box;
  border: 2px solid #2D3340;
  justify-content: space-between;
  color: #797979;
}
.customSelectCity img:not(.start-icon){
  width: 10px;
  transform: rotate(-90deg);
  position: absolute;
  right: 15px;
  top: 13px;
}
.customSelectCity img.start-icon{
  margin-right: 5px;
}

.box-shadow-images img{
  filter: drop-shadow(2px 2px 4px rgba(9, 16, 38, 0.25));
  margin-bottom: 6px;
}

.customTab{
  font-size: 14px !important;
  text-transform: none !important;
  font-weight: 600 !important;
  min-width: auto !important;
  color:#091026;
  font-family: "Barlow", sans-serif !important;
}
.customTabWrapper .MuiTabs-flexContainer{
  display: flex;
  background: #EDE6DD;
  justify-content: space-around;
}

.customTabWrapper .MuiTab-textColorPrimary.Mui-selected{
  color: #091026;
}
.customTabWrapper  .PrivateTabIndicator-colorPrimary-2{
  background-color: #091026;
}
.selectDateOnSearchForm{
  border-bottom: 2px solid #091026;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:20px;
  flex-direction: column;
}
.selectDateOnSearchForm.big-padding{
  padding: 30px;
}
.selectDateOnSearchForm.big-padding{
  background: rgb(45, 51, 64);
}
.selectDateOnSearchForm .customSelectCity{
  background: white;
}
.selectDateOnSearchForm.no-border{
  border-bottom: 0;
}
.ourSectionSlider{
  padding: 15px;
}
.ourSectionSlider img{
  max-width: 100%;
}
.MuiButtonBase-root.blackButton{
  font-weight: 600;
  font-size: 17px;
  color: #F7F7F7;
  background: #2D3340;
  text-transform: none;
  padding: 5px 40px;
  border-radius: 12px;
  font-family: "Barlow", sans-serif !important;
}
.selectDateOnSearchForm .MuiButtonBase-root.blackButton{
  width: 200px;
}
.mainPadding{
  padding: 10px;
  box-sizing: border-box;
}
.blackButton.MuiButton-root:hover{
  background: #0a1a46;
}
.appBarBgBlack.MuiAppBar-colorPrimary{
  background: #091026;
}

.appBarBgWhite.MuiAppBar-colorPrimary{
  background: #F7F7F7;
  color: #091026;
}

.MuiButtonBase-root.blackButton.small{
  font-size: 14px;
  padding: 0;
}
.appBarBgWhite.MuiAppBar-colorPrimary .MuiIconButton-colorInherit{
    position: absolute;
    padding: 0;
}

.appBarBgWhite.MuiAppBar-colorPrimary span.text{
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  padding-right: 15px;
  text-transform: capitalize;
}

.address{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  box-shadow: 4px 4px 4px rgba(9, 16, 38, 0.25);
  background: #F7F7F7;
  margin-bottom: 15px;
  position: relative;
}
.address .right{
  background: #2D3340;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 0 12px 12px 0;
  flex-basis: 65px;
  color:#F7F7F7;
  min-width: 65px;
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.address .left{
  padding: 15px;
  flex-grow: 1;
  padding-right: 75px;
}
.address .left .title{
  font-size: 18px;
  margin-bottom: 15px;
}
.address .left .subtitle{
  color:#838788;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.address .left .subtitle span:first-child{
  align-items: center;
  display: flex;
}
.appBarBgWhite .MuiSvgIcon-root{
  font-size: 30px;
}
img{
  max-width: 100%;
}
.searchResultWrapper img{
  filter: drop-shadow(2px 2px 4px rgba(9, 16, 38, 0.25));
}
.filter{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  color: #797979;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.filter span{
  color: black;
  margin-right: 5px;
}
.wrapperFilters{
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.searchResultWrapper a{
  color: #2D3340;
  display: block;
  margin-bottom: 30px;
  text-decoration: none
}
.searchResultWrapper h3{
  font-size:16px;
  margin-bottom: 10px
}
.searchResultWrapper p{
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.searchResultWrapper{
  padding: 15px;
}
.MuiAlert-standardError{
  background-color: rgb(162 44 30) !important;
  color:white !important;
}
.optionBlock{
  background: #EDE6DD;
  border-radius: 15px;
  padding:15px;
}
.optionBlock .title{
  margin-top: 0;
  font-size: 20px;
  font-weight: 600;
}
.optionBlock .line{
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-top: 1px solid #797979;
  align-items: center;
  margin-top: 0;
  font-size: 13px;
}

.optionBlock .line > span{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.optionBlock .line > span > span{
  margin-left: 3px;
  max-width: 50px;
}

.child-by-center{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.cards img{
  margin-right: 10px;
}
.cards-list .address img{
  width: 22px;
  margin-right: 10px;
}
.cards-list .address .left .subtitle{
  font-size: 15px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  max-width: 78%;
}
.cards-list .address .left .subtitle > div{
  display: flex;
}
.cards-list .address .left .title{
  font-weight: normal;
  color:#838788;
}
body .MuiButton-root.Mui-disabled {
  color: rgb(255, 255, 255, .3);
}
.extraSupplierInfo{
  font-size: 14px;
  font-weight: 400;
  color:#838788;
}
.book-btn{
  min-width: 250px !important;
}
.totalBlock .line{
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  font-weight: normal;
  font-size: 15px;
  font-family: 'Barlow', sans-serif !important;
}
.totalBlock .line.subtotal{
  font-size: 14px;
  color:#797979;
}
.supplier-conditional{
  display: flex;
  color:#797979;
  font-size: 14px;
  /*justify-content: end;*/
  border-radius: 12px;
  align-items: center;
  padding: 10px 20px;
  margin-top: 10px;
}
.supplier-conditional.blockWithImage img{
  margin-right: 20px;
}
/*.supplier-conditional ul, .supplier-conditional  ol{*/
  /*margin: 0;*/
  /*padding: 0;*/
/*}*/
ul, ol{
  margin: 0;
  padding: 0;
  padding-left: 15px;
}
.wrapperBooking{
  background: #F7F7F7;
  box-shadow: 4px 4px 4px rgba(9, 16, 38, 0.15);
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 20px;
}
.wrapperBooking .generalInfo{
  background: #EDE6DD;
  border-radius: 12px;
  padding: 17px 33px;
}
.wrapperBooking .generalInfo .line{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.wrapperBooking .generalInfo .line:last-child{
  margin-bottom: 0;
}
.wrapperBooking .generalInfo .line.general{
  font-size: 16px;
  font-weight: 600;
}
.wrapperBooking .generalInfo .line.subtitle{
  color: #797979;
  font-size: 14px;
}
.wrapperBooking .generalInfo .line.subtitle div{
  display: flex;
  align-items: center;
}
.wrapperBooking .generalInfo .line.subtitle div span{
  line-height: 20px;
  margin-left: 5px;
  font-weight: 400;
}
.wrapperBooking .blockInfo{
  padding: 12px;
  border-bottom: 1px solid #2D3340;
  margin-bottom: 20px;
  padding-bottom: 30px;
}
.wrapperBooking .blockInfo:last-child{
  border-bottom: 0;
  padding-bottom: 0;
}
.wrapperBooking .blockInfo .title{
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.cancel-booking{
  margin-top: 20px;
}
.wrapperBooking .blockInfo .title .icon{
  position: absolute;
  right: 0;
  top:-2px;
}
.wrapperBooking .blockInfo .title + div{
  margin-top: 15px;
}
.wrapperBooking .justify-between.gray-text{
  font-size: 11px;
  margin-bottom: 20px;
}
.wrapperBooking .justify-between.gray-text:last-child{
  margin-bottom: 0;
}
.wrapperBooking .justify-between.gray-text span:first-child{
  max-width: 65%;
}

.blockWithImage{
  display: flex;
  line-height: 23px;
  align-items: center;
}
.blockWithImage img{
  margin-right: 5px;
}
.justify-between{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.wrapperBooking .gray-text{
  font-size: 12px;
}
.gray-text{
  color: #797979;
}
.extraSupplierInfo .title{
  font-weight: 500;
  color:#091026
}
.subtitle{
  font-weight: 600;
  font-size: 18px;
}
.flex-by-center{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
label{
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
}

html, body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  display: none;
}
.MuiCheckbox-root .MuiSvgIcon-root{
  color: #2D3340
}

body .MuiPickersToolbar-toolbar, body .MuiPickersDay-daySelected, body .MuiPickersDay-daySelected:hover{
  background-color: #2D3340;
}
body .MuiButton-textPrimary{
  color: #2D3340
}
.selectDateOnSearchForm .MuiOutlinedInput-adornedEnd{
  padding: 0;
}
.booking-status{
  border: 1px solid #2D3340;
  display: inline-block;
  padding: 3px 25px;
  border-radius: 6px;
}
body .customTabWrapper .MuiTabs-fixed .jss2{
  background-color:#2D3340
}
body .MuiTooltip-tooltip{
  background: #2D3340;
  border-radius: 12px;
  color:#F7F7F7;
}
body .MuiTooltip-arrow{
  color: #2D3340
}
#root, .MuiDialog-root{
  max-width: 500px;
  margin: 0 auto;
}

body .selectOnSupplier{
  height: 33px
}

.appBarBgWhite.MuiAppBar-colorPrimary{
  max-width: 500px;
  left: 0;
  margin: auto;
}
.cards-list .address .left{
  padding: 15px 10px;
}

.reviewForm .rating{
  display: flex;
  justify-content: space-between;
}

body .MuiInputBase-root {
  overflow: hidden;
  border-radius: 12px;
}

.bg-main-color input:-webkit-autofill,
.bg-main-color input:-webkit-autofill:hover,
.bg-main-color input:-webkit-autofill:focus,
.bg-main-color textarea:-webkit-autofill,
.bg-main-color textarea:-webkit-autofill:hover,
.bg-main-color textarea:-webkit-autofill:focus,
.bg-main-color select:-webkit-autofill,
.bg-main-color select:-webkit-autofill:hover,
.bg-main-color select:-webkit-autofill:focus {
  -webkit-text-fill-color: #F7F7F7;
  -webkit-box-shadow: 0 0 0px 1000px #28303e inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.reviewForm .rating > div{
  border: 1px solid;
  text-align: center;
  line-height: 48px;
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 5px;
  width: 44px;
  height: 48px;
}
.reviewForm .rating > div.selected{
  background: #2D3340;
  color:#F7F7F7;
}

.textarea{
  width: 100%;
  min-height: 50px;
  border: 2px solid #2D3340;
  border-radius: 12px;
  padding: 10px;
  box-sizing: border-box;
}


#root > .dev{
  border: 2px solid yellow;
}
body.dev .MuiDialog-container{
  border: 2px solid yellow;
}
.textarea:focus{
  outline: none;
}
.sup-header{
  background: #2D3340;
  margin: -15px;
  height: 50px;
  margin-bottom: 10px;
}
.sup-header > span{
  font-weight: 600;
  font-size: 18px;
  color: #F7F7F7;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}
.App {
  text-align: center;
}
.tel-number {
  margin: 20px 0;
  display: block;
  text-align: center;
}

.login-page .tel-number{
  margin: 30px 0 20px 0;
}

.tel-number a{
  color: #091026 !important;
}
.bg-main-color .tel-number a{
  color: white !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.multiline-input > div{
  height: auto;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.share-block{
  padding: 10px;
  background: #F7F7F7;
  font-weight: bold;
  display: flex;
  border-radius: 15px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.share-block img{
  width: 18px;
  margin-right: 3px;
}
.share-desktop{
  position: absolute;
  right: 0;
  transform: translate(0, 72%);
  padding: 10px;
  font-size: 12px;
  font-weight: normal;
  border: 1px solid #eee;
  min-width: 250px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  overflow: hidden;
}
.close-share{
  margin: 0;
  margin-bottom: 3px;
  float: right;
  padding: 10px;
  text-align: right;
  font-size: 16px;
  padding-right: 0;
  padding-top: 0;
}
#share-input{
  position: absolute;
  transform: translate(-1000%,0);
}
.share-desktop .share-img{
  width: 13px;
  margin-right: 7px;
}
.green-text{
  color:darkgreen;
}
.share-desktop div{
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.share-desktop a{
  line-height: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color:rgba(0,0,0,0.87)

}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

html[class^="iPhone13,"]{
  margin-top: 44pt;
  background: white !important;
}
html[class^="iPhone13,"] .MuiDialog-paperFullScreen{
  margin-top: 50pt !important;
}

html[class^="iPhone12,"]{
  margin-top: 29pt;
  background: white !important;
}
html[class^="iPhone12,"] .MuiDialog-paperFullScreen{
  margin-top: 50pt !important;
}

html[class^="iPhone10,"]{
  margin-top: 17pt;
  background: white !important;
}
html[class^="iPhone10,"] .MuiDialog-paperFullScreen{
  margin-top: 34pt !important;
}


.premium b{
  font-weight: 600;
  font-size: 18px;
}

.premium .plan{
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 15px;
  box-sizing: border-box;
  border: 0.5px solid #797979;
  border-radius: 12px;
}
.premium .plan img{
  margin-right: 3px;
}
.premium .plan.black{
  background: #495468;
  border: 0.5px solid #333E56;
  color:white;
}
.premium .plan.black .info{
  color:white;
}

.premium .plan .title{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.premium .plan .title b{
  display: inline;
}
.premium .plan .title .text{
  font-weight: 600;
  font-size: 20px;
}

.premium .plan .info{
  font-size: 14px;
  line-height: 17px;
  color: #797979;
}

.premium .actions{
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.premium .actions div{
  min-width: 180px;
  padding: 6px;
  font-weight: 600;
  border-radius: 13px;
  border: 1px solid white;
  text-align: center;
  cursor: pointer;
}

.subscription-title{
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 10px;
}

.subscription-payment{
  padding: 20px;
}

.subscribe-payment-dialog .searchHeader{
  justify-content: flex-end;
}

.subscription-payment hr{
  margin: 20px 0;
}

.total-subscription{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.commission_plan{
  display: flex;
  padding: 13px;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #797979;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 16px;
}
.commission_plan b{
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
}
.commission_plan span{
  font-weight: normal;
  font-size: 14px;
  color: #797979
}

.commission_plan.premium{
  background: #495468;
  border-color: #79808D;
  color:white;
}

.commission_plan.premium span{
  color:white;
}

.commission_plan.premium span.old-price{
  text-decoration: line-through;
  margin-right: 5px;
}

.upgrade-plan{
  text-align: center;
  margin: 12px;
  text-decoration: underline;
  display: block;
  cursor: pointer;
}

.subscription-info-for-booking .info{
  display: flex;
  justify-content: space-between;
}

.subscription-info-for-booking .dots{
  flex-grow: 1;
  border-bottom: 0.5px dashed #ABABAB;
  align-items: center;
  margin: 0 5px 2px;
}

.subscription-info-for-booking img{
  margin-right: 5px;
}
.subscription-info-for-booking b{
  margin-right: 5px;
}

.subscription-info-for-booking .price{
  display: flex;
}

.subscription-info-for-booking .price .old-price{
  text-decoration: line-through;
  margin-right: 10px;
}

.address.select-card-on-subscribe .left .subtitle{
  display: flex;
  font-weight: normal;
  align-items: center;
  font-size: 15px;
}

.address.select-card-on-subscribe .left .date{
  font-weight: normal;
  font-size: 15px;
}

.address.select-card-on-subscribe .left .subtitle div:first-child{
  display: flex;
  align-items: center;
}
.address.select-card-on-subscribe .left .subtitle img{
  margin-right: 5px;
}

.address.select-card-on-subscribe{
  font-weight: normal;
  font-family: Barlow;
  color: #838788;
}

.address.select-card-on-subscribe .right{
  background: none;
}

.subscribe-extra-info{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  color: #797979;
  height: 20vh;
}

.newMessageCounter{
  position: absolute;
  width: 16px;
  height: 16px;
  background: #bb3636;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  color:white;
  font-size: 11px;
  margin-left: -5px;
}
.subscribe-extra-info a{
  text-decoration: underline;
}

.text-center{
  text-align: center;
}
.create-account-link{
    margin-top: 30px;
    text-align: center;
}

.product{
    position: relative;
    padding: 23px 23px 23px 5px;
    margin-top: 30px;
    background: #495468;
    border: 2px dashed #495468;
    box-sizing: border-box;
    border-radius: 12px;
    color:white;
    cursor: pointer;
}


.product .content{
    display: flex;
    width: 100%;
}

.product .content > div:last-child{
    flex-grow: 1;
}

.product .content .top{
    display: flex;
    justify-content: space-between;
}

.product .MuiRadio-colorSecondary.Mui-checked{
    color:white;
}

.product .MuiRadio-root{
    color:white;
}

.product .MuiInput-underline:before{
    border-color:white;
}

.product .MuiInput-underline:hover:not(.Mui-disabled):before, .product .MuiInput-underline:focus:not(.Mui-disabled):before{
    border-color:white;
}
.product .MuiSelect-icon{
    fill:white;
}

.product .MuiSelect-select.MuiSelect-select{
    color:white;
}

.product .MuiInputLabel-formControl{
    color: white !important;
    font-size: 14px;
    font-weight: normal;
    padding-top: 7px;
}

.product.disabled:after{
    content:"";
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0,0,0,.3);
    z-index: 11;
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.product .product-name{
    font-size: 20px;
    font-weight: 600;
}
.product .info{
    margin-top: 10px;
    font-size: 14px;
    font-weight: normal;
}

.product .product-price{
    font-size: 18px;
    font-weight: 600;
}

.product .product-price small{
    font-size: 10px;
    font-weight: 400;
}

.soon{
    display: none;
    position: absolute;
    left: -1px;
    top: -1px;
    background: #AEB3BF;
    color:white;
    z-index: 12;
    padding: 7px;
    border-radius: 7px 0 7px 0;
}


.disabled .soon{
    display: block;
}

.plans-from-profile .product.disabled{
    background: white;
    color: #AEB3BF
}

.plans-from-profile .product.disabled:after{
    background: rgba(0,0,0,.03);
}

.plans-from-profile .bottom{
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.register-subscription .bg-main-color .top{
    text-align: center;
    margin: 30px 0;
}
.register-subscription .bg-main-color .top h2{
    font-size: 25px;
}

.register-subscription .bg-main-color .top p{
    font-size: 16px;
}

.register-subscription .bg-main-color.bottom{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.register-subscription .bg-main-color.bottom p{
    margin: 15px 0;
}

.conform-email-screen p{
    font-weight: bold;
    max-width: 80%;
    text-align: center;
}

.conform-email-screen-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
}

.logo-on-register-page{
    width: 150px;
}

.register-notifications{
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    z-index: 1;
    top:0;
    color:white;
    padding: 15px;
    text-align: center;
    font-size: 16px;
    box-sizing: border-box;
    font-family: Barlow;
    font-weight: 600;
    max-width: 500px;
    width: 100%;
    margin: auto;
}

.back-to-login{
    margin-bottom: 20px;
}
.topbar-forgot-pwd{
    position: fixed !important;
    width: 100%;
    max-width: 500px;
}
.switcher-option{
    display: flex;
    background: #E7E7E7;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 50px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    width: 320px;
    max-width: 100%;
    margin: 40px auto;
    justify-content: space-between;
    position: relative;
}

.wrapper-calendar-flex-box{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /*flex-grow: 1;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}

.wrapper-calendar{
    width: 100%;
}
.switcher-option .background-switcher-option-animation{
    position: absolute;
    width: 100%;
    height: 100%;
    flex-basis: 100%;
    z-index: 0;
    padding: 3px 5px;
    box-sizing: border-box;
    transition: .3s
}

.switcher-option .background-switcher-option-animation .active{
    background: #2D3340;
    border-radius: 50px;
    width: 50%;
    height: 100%;
    transition: .3s;
    transform: translate(0px, 0px);
}
.switcher-option .background-switcher-option-animation .active.right-side{
    transform: translate(100%, 0px);
}
.switcher-option>div{
    flex-basis: 50%;
    text-align: center;
    padding: 10px 0;
    z-index: 1;
    transition: .2s;
}
.switcher-option>div:not(.active){
    cursor: pointer;
}
.switcher-option>div.active{
    color:white;
}

.rbc-day-bg{
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    padding-bottom: 15px;
}

.rbc-day-bg:after{
     font-style: normal;
     font-weight: 300;
     font-size: 9px;
     position: absolute;
     bottom: 18px;
}

.wrapper-calendar.lunch .rbc-day-bg:after{
    content: attr(data-lunch);
}

.wrapper-calendar.dinner .rbc-day-bg:after{
    content: attr(data-dinner);
}

.rbc-month-header{
    padding-bottom: 14px;
    border-bottom: 1px solid #2D3340;
}

.rbc-toolbar{
    margin-left: 20px;
    font-size: 18px;
}


.guest_calendar{
    background: #F7F7F7;
    width: 100vw;
    height: calc( 100vh - 114px );
    display: flex;

    justify-content: center;
    flex-wrap: wrap;
    max-width: 500px;
    flex-direction: column;
}

.wrapper_calendar{
    width: 100%;
    background: white;
    padding: 26px 7px 26px 0;
    box-sizing: border-box;
    box-shadow: 0px -1px 28px -2px rgba(34, 60, 80, 0.2) inset;
    margin-bottom: 60px;
}

.guest_calendar_title{
    background: #2D3340;
    height: 70px;
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #F7F7F7;
    line-height: 70px;
}
.guest_calendar .loader{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

}

.week_line{
    margin-bottom: 26px;
}
.week_line:last-child{
    margin-bottom: 0;
}
.days_of_weeks, .week_line .line{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}
.days_of_weeks{
    padding: 0 7px 15px 0;
}
.days_of_weeks > div, .week_line .line > div{
    width: 44px;
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #797979;
    cursor: pointer;
}
.week_line .line > div.current{
    background: #EDE6DD !important;
}
.week_line .line.top > div:not(.month-label){
    background: #2D3340;
    border-radius: 10px 10px 0px 0px;
    color:white;
}

.week_line .line.top > div.previous:not(.month-label){
    background: #F7F7F7 !important;
    color:#797979 !important;
}
.week_line .line.top > div.no-options:not(.month-label){
    background: #797979;
}

.week_line .line.top > div{
    height: 20px;
    line-height: 20px;
}

.line.lunch{
    border-bottom: 0.5px solid rgba(121, 121, 121, 0.2);
}

.line .month-label{
    font-weight: 600;
    font-size: 12px;
    font-family: Barlow;
    text-transform: capitalize;
    color: #2D3340 !important;
}

.line.lunch div, .line.dinner div{
    font-size: 9px;
    color: #2D3340;
    min-height: 12px;
}
.line.lunch div:not(.type), .line.dinner div:not(.type){
    padding: 7px 0;
    background:  #F7F7F7;
    font-weight: 300;
}

.line.dinner div:not(.type){
    border-radius: 0 0 10px 10px;
}

.line .type{
    font-family: Barlow !important;
    font-weight: 300 !important;
}

.wrapper_calendar .actions{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color:#797979;
    font-size: 12px;
}

.rbc-calendar{
    width: 100%;
}

.wrapper_calendar .actions > div{
    margin-right: 20px;
}

.wrapper_calendar .actions > div:last-child{
    margin-right: 0;
}

.wrapper_calendar .actions .current-date{
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper_calendar .actions .current-date .arrow{
    width: 35px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.wrapper_calendar .actions .next, .wrapper_calendar .actions .back{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #2D3340;
    color: #2D3340;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.calendar-info{
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #797979;
    text-align: center;
    width: 100%;
    margin: 15px 0;
    margin-bottom: 0;
}
.supplier-name-on-calendar{
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: rgb(247, 247, 247);
    background: rgb(45, 51, 64);
}
.prices-info-on-the-buttom{
    background: #efdabc;
    text-align: center;
    padding: 15px;
    color:#2D3340;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    position: fixed;
    bottom: 0;
    width: 500px;
    box-sizing: border-box;
    max-width: 100%;
    z-index: 15;
}

.close-price-info-button{
    position: absolute;
    right: 20px;
    top: 20px;
}
.price-info-wrapper .title{
    font-weight: 600;
    font-size: 18px;
    color: #2D3340;
    margin-bottom: 30px;
}

.price-info-wrapper{
    padding: 30px 20px;
}
.price-info-wrapper .content p{
    margin-bottom: 20px;
}
.price-info-wrapper .content{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #797979;
}

.prices-info-on-the-buttom img{
    margin-left: 5px;
}

@media (max-width: 400px) {
    .days_of_weeks > div, .week_line .line > div{
        width: 35px;
        font-size: 8px;
    }
    .rbc-day-bg:after{
        bottom: 22px;
        font-size: 8px;
    }
}

@media (max-height: 720px) {
    .supplier-name-on-calendar{
        min-height: 50px;
        font-size: 16px;
    }
    .switcher-option>div{
        padding: 7px 0;
    }
    .rbc-toolbar .rbc-toolbar-label{
        font-size: 14px;
    }
}
@media (max-height: 670px) {
   .supplier-name-on-calendar{
       min-height: 40px;
       font-size: 15px;
   }
    .rbc-month-header{
        margin: 5px 0;
    }
    .wrapper_calendar{
        padding: 15px 0 20px 0;
    }
}
.main-logo{
    margin-right: 20px;
}

.ourSectionSlider .react-swipeable-view-container > div:last-child > div{
    height: calc(100% - 55px);
}

.searchResultWrapper a{
    margin-bottom: 10px;
    margin-top: 30px;
    position: relative;
}
.price_from{
    position: absolute;
    right: 0;
    font-weight: bold;
    background: rgba(255,255,255, .92);
    padding: 5px 15px;
    transform: translate(0, -111%);
    font-size: 14px;
}
.price_from span{
    font-weight: normal;
}
.searchResultWrapper h3{
    margin-top: 10px;
}
.searchResultWrapper a p{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #2D3340;
    margin-top: 14px;
}

.searchResultWrapper .short_description{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #797979;
    display: flex;
}
.searchResultWrapper .short_description .content{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchResultWrapper .tags{
    display: flex;
    margin-top: 9px;
    flex-wrap: wrap;
}

.searchResultWrapper .tags div{
    padding: 0 10px;
    height: 23px;
    background: #797979;
    opacity: 0.4;
    color:white;
    border: 1px solid #797979;
    box-sizing: border-box;
    border-radius: 12px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.searchResultWrapper .moreless{
    color: #2D3340;
    text-decoration: underline;
    cursor: pointer;
}

.short_description.show-all{
    display: block;
}
.short_description.show-all .content{
    white-space: break-spaces;
}

.start-icon{

}
.global-notification-block{
    position: absolute;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    bottom: 54px;
}

.notification{
    display: flex;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.notification img{
    margin-right: 10px;
}

.notification.message{
    background: #f6e2c4;
    color: #E29D34;
}

.notification-content{
    display: flex;
    align-items: center;
}

.notification.booking-suggest{
    background: #D7E1F8;
}
.booking-is-ready-info p{
    margin: 0;
    font-size: 12px;
    margin-bottom: 10px;
    color:#797979
}

.booking-is-ready-info b{
    font-size: 14px;
    color: #2D3340;
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
}

.add-new-card{
    margin-bottom: 40px;
}

.payment-form-on-supplier-page label{
    margin-bottom: 13px;
}
body .payment-form-on-supplier-page .MuiFormControl-fullWidth{
    min-height: 65px;
}
body .payment-form-on-supplier-page .new-card-payment-form-wrapper{
    overflow: hidden;
}
body .payment-form-on-supplier-page .new-card-payment-form-wrapper .MuiInputBase-root{
    margin-bottom: 14px;
}
body .pay-button-on-supplier-page{
    margin-bottom: 30px;
}

.payment-form-on-supplier-page{
    overflow: hidden;
}
.topbar-booking-popup{
    min-height: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: rgb(247, 247, 247);
    background: rgb(45, 51, 64);
    font-weight: 600;
}

.back-to-search{
    background: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    left: 15px;
}

.supplier-wrapper {
    position: relative;
}

.changeDateWrapper{
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 0 15px 0 15px;
}

.changeDateWrapper .MuiSelect-outlined.MuiSelect-outlined{
    color: #797979;

}
.changeDateWrapper .MuiOutlinedInput-notchedOutline{
    border: 1px solid #2D3340 !important;
}
.changeDateWrapper > span{
    font-size:14px;
    font-weight: 500;
    color: #797979;
}
.back-to-search svg{
    margin-left: 8px;
}

.sliderDots{
    width: 100vw;
    position: absolute;
    margin-top: -35px;
    justify-content: center;
    display: flex;
}

.sliderDots > div{
    background: rgba(0, 0, 0, 0.7);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 1px 39px 25px -23px rgba(34, 60, 80, 0.16);
    margin-right: 8px;
}
.sliderDots > div:last-child{
    margin-right: 0;
}

.sliderDots > div.active{
    background: #FFFFFF;
}

.lastMinuteBookingWrapper{
    background: #F7F7F7;
    padding: 12px;

}

.lastMinuteBookingWrapper .title{
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #797979;
    padding-bottom: 15px;
}

.lastMinuteBookingWrapper .title img{
    margin-right: 12px;
}

.lastMinuteBookingWrapper .content{
    margin-top: 20px;
}
.lastMinuteBookingWrapper .content p{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #797979;
    margin: 0;
}

.lastMinuteBookingWrapper .content .line{
    display: flex;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2D3340;
}

.lastMinuteBookingWrapper .content .sep{
    flex-grow: 1;
    border-bottom: 0.2px dashed #797979;
    background: none;
    margin: 13px 7px;
    width: auto;
}

.minimum-spending{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #2D3340;
    margin-bottom: 20px;
}

.commission_plan p{
    margin: 0;
}

.supplier-wrapper .switcher-option{
    margin: 20px auto;
}

.wrapperBooking.booking-on-supplier-page{
    background: #F7F7F7;
    box-shadow: 4px 4px 12px rgba(9, 16, 38, 0.15);
    border-radius: 12px;
    padding: 24px;
}

.wrapperBooking.booking-on-supplier-page .generalInfo{
    background: none;
    padding: 0;
    border-bottom: 1px solid #2D3340;
    padding-bottom: 30px;
    border-radius: 0;
}

.wrapperBooking.booking-on-supplier-page .line{
    display: flex;
    justify-content: space-between;
}

.wrapperBooking.booking-on-supplier-page .line .title{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #2D3340;
    text-align: left;
}
.wrapperBooking.booking-on-supplier-page .line:last-child{
    color: #2D3340;
}

.wrapperBooking.booking-on-supplier-page .line .subtitle,  .wrapperBooking.booking-on-supplier-page .small{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    color: #797979;
    font-size: 12px;
    margin: 0;
}

.wrapperBooking.booking-on-supplier-page .blockInfo{
    padding: 0;
    margin-top: 30px;
    padding-bottom: 30px;
}

.wrapperBooking.booking-on-supplier-page .canceling-condition-block .line{
    margin-bottom: 10px;
}


.wrapperBooking.booking-on-supplier-page .canceling-condition-block .justify-between{
    margin-bottom: 15px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
}

.wrapperBooking.booking-on-supplier-page .blockInfo.canceling-condition-block{
    padding-bottom: 0;
}

.close-booking-modal-first-step{
    position: absolute;
    right: 10px;
    font-size: 25px;
    top: 10px;
    padding: 10px;
}

.confirm-booking-button{
    margin-bottom: 40px;
}
.booking-first-step-popup{
    margin-bottom: 40px;
    padding: 0 10px;
}
.booking-first-step-popup label{
    font-size: 20px;
    margin-bottom: 20px;
    color:#2D3340;
}
.booking-first-step-popup .MuiInputBase-formControl{
    margin-bottom: 20px;
}
.first-step-booking-dialog-wrapper .MuiDialog-paperScrollPaper{
    justify-content: end;
}

.optionBlock .title{
    margin-bottom: 13px;
}

.optionBlock .line{
    color: #797979
}

.extraSupplierInfo .title{
    margin-top: 0;
}
.status40, .status25{
    background: #797979;
    color: #F7F7F7;
    font-size: 14px;
    font-weight: 600;
    border-color: #797979;
}
.status40{
    background: #C1C1C1;
    border-color: #C1C1C1;
}
.status25.active{
    background: #2D3340;
    color: #F7F7F7;
    font-size: 14px;
    font-weight: 600;
    border-color: #2D3340;
}
.status30, .status60{
    background: #F7F7F7;
    border: 0.5px solid #2D3340;
    box-sizing: border-box;
    border-radius: 5px;
    color:#2D3340;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}


.chat-button{
    right: 65px;
    position: absolute;
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.booking-card{
    padding: 15px;
}

.accordion .content{
    display: none;
}
.accordion.open .content{
    display: block;
}

.accordion.open .icon{
    transform: rotate(180deg);
}

.suggestion{
    display: flex;
    margin: 10px 0;
    font-family: Barlow;
    color:#2D3340;
    font-size: 14px;
    font-weight: 500;
}
.suggestion > div:first-child{
    margin-right: 10px;
}
.suggestion > .old-time, .suggestion > .new-time{
    width: 35%;
}
.suggestion .old-time{
    color: #797979
}

.suggestion .arrow{
    display: flex;
    align-items: center;
    margin: 0 15px;
}
.suggestion .arrow img{
    transform: rotate(180deg);
    width: 10px;
    margin: 0;
}
.suggestion .reject{
    background: #F7F7F7;
    color:#797979;
    border: 1px solid #797979
}

.suggestion .reject:hover{
    background: #F7F7F7;
}

.suggestion .blackButton{
    width: 100%;
    border: 1px solid #2D3340;
    border-radius: 5px;
    margin-top: 10px;
}

.suggestion-info{
    font-size: 12px;
    color:#797979;
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
}
.suggestion-info > span{
    color:#2D3340
}

.suggestion img{
    margin-top: 4px;
}
.chat-btn{
    background: #F6E1C2;
    border-radius: 5px;
    padding: 4px 15px;
    color:#E29D34;
    display: flex;
    align-items: center;
    font-size: 14px;
    position: relative;
    margin-right: 10px
}
.chat-btn:after{
    content: "";
    border-radius: 50%;
    position: absolute;
    width: 9px;
    height: 9px;
    background: #E29D34;
    top: -4px;
    right: 6px;
}
.chat-btn img{
    margin-right: 10px;
}
.wrapper-booking-content-on-list{
    display: flex;
    flex-wrap: wrap;
}
.chat{
    background: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.chat .top{
    display: flex;
    align-items: center;
    padding: 20px 16px;
    box-sizing: border-box;
}

.chat .top .dots-icon{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-right: 26px;
}
.chat .top .dots-icon div{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #2D3340;
    margin-bottom: 3px;
}
.chat .top .avatar{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    position: relative;
}


.chat .top .avatar img{
    max-width: initial;
    min-width: 100%;
    position: absolute;
}

.chat .top .avatar.supplier_avatar img{
    width: 200%;
    left: -50%;
}

.chat .messages{
    padding: 8px 16px;
    box-sizing: border-box;
    flex-grow: 1;
    max-height: calc(100vh - 220px);
    box-shadow: 0px -19px 66px -19px rgba(34, 60, 80, 0.2) inset;
    overflow: auto;
}

.chat .message{
    margin-bottom: 16px;
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
}

.chat .message .date{
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    color: #797979;
    margin-bottom: 5px;
    flex-basis: 100%;
}

.chat .message .content{
    padding: 15px;
    box-sizing: border-box;
    color:white;
    font-size: 16px;
    max-width: 80%;
}

.chat .message.left .content{
    background: #2D3340;
    border-radius: 15px 15px 15px 0px;
}

.chat .message.right .content{
    background: #495468;
    border-radius: 15px 15px 0px 15px;
}
.chat .message.right{
     justify-content: flex-end;
}

.chat .message.right .date{
    text-align: right;
}

.chat .actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px;
    box-sizing: border-box;
    flex-wrap: wrap;
}
.chat .actions .wrapper-actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 100%;
}
.chat .actions .wrapper-actions label{
    margin: 0;
}
.chat .actions .MuiTextField-root{
    flex-grow: 1;
    margin-left: 16px;
}

body .chat .actions .MuiInputBase-root{
    height: auto !important;
    padding: 10px;
}

.sendIcon, .attachmentIcon{
    cursor: pointer;
}

video{
    width: 100%;
}

.chat-image{
    max-width: 100%;
    height: auto !important;
}
.chat-image img{
    max-width: 100% !important;
    height: auto;
}

.chat-attachments{
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
}

.chat-attachments .attachment{
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
}

.chat-attachments .attachment .MuiCircularProgress-colorPrimary{
    position: absolute;
    top: 0;
    z-index: 222222;
    color: white;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.remove-attachment{
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    /*font-size: 7px;*/
    width: 15px;
    line-height: 10px;
    text-align: center;
    height: 15px;
    right: 2px;
    top:2px;
    background: rgba(255,255,255,.5);
}

.chat-attachments .attachment img{
    max-width: 200%
}
.supplier-interface .optionBlock{
    padding: 20px;
}
.day-block > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.day-block{
    margin-bottom: 15px;
}
.day-block-wrapper{
    margin-bottom: 20px;
}
.sub-title{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
}
.by{
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: 500;
    font-size: 13px;
    flex-basis: 100%;
}
.by > span{
    font-size: 13px;
    font-weight: normal;
    color: #797979;
    margin-left: 5px;
}
.by a {
    color:#2D3340 !important;
}
.diet{
    font-size: 13px;
    font-weight: normal;
    color: #797979;
}
.small-btn{
    font-size: 13px !important;
    padding: 5px 15px !important;
}
.blackButton.small-btn.gray{
    background: #888888;
}
.blackButton.small-btn.blue{
    background: #2D3340;
}
.justify-between{
    display: flex;
    justify-content: space-between;
    width:100%;
    align-items: center;
    margin-bottom: 15px;
}
.title{
    margin-bottom: 0;
}
.scrollToTargetButton.to-top img{
    transform: rotate(90deg);
    width: 12px;
    text-align: center;
}
.scrollToTargetButton.to-down img{
    transform: rotate(-90deg);
    width: 12px;
    text-align: center;
}
.scrollToTargetButton{
    position: -webkit-sticky;
    position: sticky;
    width: 40px;
    height: 40px;
    background: rgba(0,0,0,.2);
    border-radius: 50%;
    z-index: 1;
    bottom: 40px;
    left: 10px;
    align-items: center;
    justify-content: center;
    margin: auto 0px auto auto;
    cursor: pointer;
    display: none;
    opacity: 0;
    transition: .5s;
}
.scrollToTargetButton.show{
    display: flex;
    opacity: 1;
}
.optionBlock:last-child .line{
    border-bottom: 1px solid #797979;
}
.optionBlock{
    margin-bottom: 15px;
}

.optionBlock .line > span > span{
    max-width: inherit;
}

.popup-title{
    font-size: 24px;
    font-weight: 500;
}

.suggestion-row{
    display: flex;
}

.suggestion-row > div{
    flex-basis: 50%;
}

.flex-align-center{
    align-items: center;
    display: flex;
}
.supplier-interface .switcher-option{
    width: 240px;
    left: 0;
    right: 0;
    top: 15px;
    z-index: 1;
    margin: 0 auto;
    margin-bottom: 40px;
    box-shadow: 4px 4px 4px rgb(9 16 38 / 25%);
}




@media only screen and (max-width: 400px) {
    .supplier-interface .optionBlock{
        padding: 15px;
    }

    .booking-status-checkbox{
        padding: 5px 15px !important;
        margin: -15px -15px 15px !important;
    }

    .booking-status-checkbox .label{
        font-weight: 500;
        font-size: 16px;
    }

    .by .blackButton.small-btn.gray{
        background: #888;
        padding: 2px 5px !important;
        margin: 0 5px;
        line-height: 1;
        font-size: 12px !important;
    }


}
@charset "UTF-8";
.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0; }

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled].rbc-btn {
  cursor: not-allowed; }

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0; }

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit; }

.rbc-abs-full, .rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.rbc-ellipsis, .rbc-event-label, .rbc-row-segment .rbc-event-content, .rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rbc-rtl {
  direction: rtl; }

.rbc-off-range {
  color: #999999; }

.rbc-off-range-bg {
  background: #e6e6e6; }

.rbc-header {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border: 0!important; }
  .rbc-header + .rbc-header {

    border: 0 !important; }
  .rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid #DDD; }
  .rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
  height: 100%;
  line-height: 50px;
}

.rbc-today {
  background-color: #eaf6ff; }

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px; }
  .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    font-weight: 600;
    font-size: 18px;
    color: #2D3340;
    padding: 0 !important;
    text-align: left !important;
  }
  .rbc-toolbar button {
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: .375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap; }
    .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
      background-image: none;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      background-color: #e6e6e6;
      border-color: #adadad; }
      .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
        color: #373a3c;
        background-color: #d4d4d4;
        border-color: #8c8c8c; }
    .rbc-toolbar button:focus {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad; }
    .rbc-toolbar button:hover {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad; }

.rbc-btn-group {
  display: none !important;
}
  .rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .rbc-btn-group button + button {
    margin-left: -1px; }
  .rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -1px; }
  .rbc-btn-group + .rbc-btn-group,
  .rbc-btn-group + button {
    margin-left: 10px; }

.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left; }
  .rbc-slot-selecting .rbc-event {
    cursor: inherit;
    pointer-events: none; }
  .rbc-event.rbc-selected {
    background-color: #265985; }
  .rbc-event:focus {
    outline: 5px auto #3b99fc; }

.rbc-event-label {
  font-size: 80%; }

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5); }

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-row {
  display: flex;
  flex-direction: row; }

.rbc-row-segment {
  display: none !important;
}

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1); }

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal; }

.rbc-month-view {
  border: 0 !important;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%; }

.rbc-month-header {
  display: flex;
  flex-direction: row; }

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 7px 0 !important;
  flex: 1 0;
  flex-basis: 0px;
  overflow: hidden;
  max-height: 61px !important; }
  .rbc-month-row + .rbc-month-row {
    border-top: none !important;
    padding: 7px 0 !important;
  }

.rbc-date-cell {
  flex: 1 1;
  min-width: 0;
  font-weight: bold;
  font-size: 16px;
  padding: 0 !important;
  text-align: center !important; }
  .rbc-date-cell.rbc-now {
    font-weight: bold; }
  .rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0;
  padding: 7px 0px !important;
  justify-content: space-around !important;
  overflow: hidden;
}

.rbc-off-range{
  opacity: 0;
  background: none !important;
}
.rbc-day-bg {
  flex: 1 0;
  margin: 0 5px;
  /*height: 50px;*/
}
  /*.rbc-day-bg + .rbc-day-bg {*/
    /*border-left: none !important;*/
  /*}*/
  .rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #DDD; }

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px; }
  .rbc-overlay > * + * {
    margin-top: 1px; }

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px; }

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: auto; }
  .rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid #DDD;
    border-spacing: 0;
    border-collapse: collapse; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
      padding: 5px 10px;
      vertical-align: top; }
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
      padding-left: 15px;
      padding-right: 15px;
      text-transform: lowercase; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left: 1px solid #DDD; }
    .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left-width: 0;
      border-right: 1px solid #DDD; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
      border-top: 1px solid #DDD; }
    .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
      padding: 3px 5px;
      text-align: left;
      border-bottom: 1px solid #DDD; }
      .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
        text-align: right; }

.rbc-agenda-time-cell {
  text-transform: lowercase; }
  .rbc-agenda-time-cell .rbc-continues-after:after {
    content: ' »'; }
  .rbc-agenda-time-cell .rbc-continues-prior:before {
    content: '« '; }

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap; }

.rbc-agenda-event-cell {
  width: 100%; }

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%; }
  .rbc-time-column .rbc-timeslot-group {
    flex: 1 1; }

.rbc-timeslot-group {
  border-bottom: 1px solid #DDD;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap; }

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none; }

.rbc-label {
  padding: 0 5px; }

.rbc-day-slot {
  position: relative; }
  .rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0; }
    .rbc-day-slot .rbc-events-container.rbc-rtl {
      left: 10px;
      right: 0; }
  .rbc-day-slot .rbc-event {
    border: 1px solid #265985;
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute; }
  .rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto; }
  .rbc-day-slot .rbc-event-content {
    width: 100%;
    flex: 1 1;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em; }
  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f7; }

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #DDD;
  z-index: 10;
  margin-right: -1px; }

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden; }

.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0;
  flex-basis: 0px; }

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px; }

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1;
  flex-basis: 0 px; }

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px; }

.rbc-time-slot {
  flex: 1 0; }
  .rbc-time-slot.rbc-now {
    font-weight: bold; }

.rbc-day-header {
  text-align: center; }

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px; }

.rbc-slot-selecting {
  cursor: move; }

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  border: 1px solid #DDD;
  min-height: 0; }
  .rbc-time-view .rbc-time-gutter {
    white-space: nowrap; }
  .rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative; }
  .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
    border-left: 1px solid #DDD; }
  .rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4; }
  .rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px; }

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row; }
  .rbc-time-header.rbc-overflowing {
    border-right: 1px solid #DDD; }
  .rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #DDD; }
  .rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #DDD; }
  .rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD; }

.rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-header-content {
  flex: 1 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #DDD; }
  .rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #DDD; }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD;
    flex-shrink: 0; }

.rbc-time-content {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative; }
  .rbc-time-content > .rbc-time-gutter {
    flex: none; }
  .rbc-time-content > * + * > * {
    border-left: 1px solid #DDD; }
  .rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #DDD; }
  .rbc-time-content > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none; }

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none; }


div.rbc-month-view > div.rbc-row.rbc-month-header span{
  width: 17px;
  visibility: hidden;
  display: block;
  color: #797979;
  font-size: 16px;
  margin: auto;
}
div.rbc-month-view > div.rbc-row.rbc-month-header span:first-letter{
  visibility: visible;
}
.rbc-month-header{
  margin-bottom: 15px;
  margin-top: 10px;
}
.rbc-date-cell > a{
  position: relative;
  z-index: -999999;
}
@media screen and (max-width: 400px) {
  .rbc-day-bg {
    height: 40px;
    margin: 0 2px;
  }
}
@media screen and (max-width: 360px) {
  .rbc-day-bg {
    padding-bottom: 12px;
  }
  .rbc-date-cell{
    font-size: 14px !important;
  }

  .rbc-day-bg:after{
    bottom: 24px;
  }
}
.sep{
    width: calc(100% + 30px);
    margin: 20px -15px 10px;
    height: 1px;
    background: #2D3340;
}

.sup-header {
    position: relative;
}
.date{
    font-weight: 600;
    font-size: 20px;
}
.close-day{
    position: absolute !important;
    color: white !important;
    left: 10px;
}
.services .optionBlock{
    background: #F7F7F7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.services .optionBlock:last-child .line{
    border-bottom: 0 !important;
}

.blackButton.small-btn.blue{
    background: #2D3340;
}

.rbc-day-bg[notifications]:before{
    content: attr(notifications);
    width: 12px;
    height: 12px;
    line-height: 12px;
    background: #bb3636;
    color:white;
    border-radius: 50%;
    position: absolute;
    text-align: center;
    font-size:9px;
    margin-top: -15px;
    margin-right:-15px;
}

.booking-status-checkbox{
    margin: -20px;
    padding: 12px 20px;
    background: #2D3340;
    color: white;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px 15px 0 0;
}
.booking-status-checkbox .small-btn{
    padding: 3px 15px !important;
}
.booking-status-checkbox .accept-btn{
    border: 1px solid white;
}
.booking-status-checkbox .MuiFormControlLabel-labelPlacementStart{
    margin: 0;
}
.booking-status-checkbox .MuiCheckbox-root .MuiSvgIcon-root{
    color:white;
}

.booking-status-checkbox .label{
    font-weight: 500;
    font-size: 18px;
}

.booking-status-checkbox .MuiTypography-root{
    font-size: 12px;
}
.plans-from-profile{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}
.subscribe-extra-info{
    margin-bottom: 40px;
}
.wrapper-pay-entrance-fees{
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    overflow: scroll;
}

.wrapper-pay-entrance-fees.payment-step{
    justify-content: unset;
}

.wrapper-pay-entrance-fees img{
    width: 40px;
}

.wrapper-pay-entrance-fees .title{
    color:white;
    font-family: Barlow;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    margin-top: 25px;
}

.wrapper-pay-entrance-fees .title p{
    font-size: 18px;
    margin-top: 0;
}

.top-block{
    text-align: center;
    padding-bottom: 40px;
    width: 100%;
    border-bottom: 0.5px solid white;
}

.wrapper-pay-entrance-fees .content{
    width: 100%;
}

.wrapper-pay-entrance-fees.payment-step .content{
    flex-grow: 1;
}
.wrapper-pay-entrance-fees.payment-step .top-block{
    margin-top: 20px;
}

.payment-wrapper-entrance-fees{
    display: flex;
    flex-wrap: wrap;
}


.wrapper-pay-entrance-fees .price-line{
    color:white;
    font-family: Barlow;
    font-weight: 600;
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.wrapper-pay-entrance-fees .price-wrapper{
    display: flex;
}

.wrapper-pay-entrance-fees .price-wrapper .price{
    margin-left: 25px;
}

.wrapper-pay-entrance-fees .price-wrapper .old-price{
    text-decoration: line-through;
    font-weight: normal;
}

.referral-code-form{
    margin-top: 60px;
    color:white;
}


.referral-code-form .MuiOutlinedInput-root, .wrapper-pay-entrance-fees-form .MuiOutlinedInput-root{
    background: rgba(247, 247, 247, 0.3);
    color:white;
    border-radius: 12px;
}

.referral-code-form .label{
    margin-bottom: 10px;
}
.referral-code-form .wrapper-input{
    display: flex;
}

.btn-referral-submit{
    cursor: pointer;
    border: 2px solid #F7F7F7;
    box-sizing: border-box;
    border-radius: 12px;
    margin-left: 12px;
    text-align: center;
    width: 62px;
    display: flex;
    justify-content: center;
    height: 45px;
}

.btn-referral-submit img{
    width: 22px;
}

.success-message{
    color: white;
    margin-top: 10px;
}

.wrapper-pay-entrance-fees-form{
    color:white;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.wrapper-pay-entrance-fees-form .MuiOutlinedInput-root{
    margin-bottom: 10px;
}

.wrapper-pay-entrance-fees .MuiOutlinedInput-notchedOutline{
    border-color: white !important;
}

.wrapper-pay-entrance-fees-form .price-line{
    margin-bottom: 50px;
}

.wrapper-pay-entrance-fees-form .title{
    margin: 0;
    font-size: 18px;
}


.wrapper-pay-entrance-fees-form .MuiCheckbox-root .MuiSvgIcon-root{
    color:white;
}

.confirm-step .text-content{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.confirm-step .text-content p{
    margin: 0;
    margin-bottom: 10px;
}

.confirm-step .product{
    background: none;
    border: 2px solid white;
    padding: 20px;
    width: 100%;
}

.old-price{
    text-align: right;
}

.old-price span{
    text-decoration: line-through;
}
.confirm-step{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
}
.product .info{
    font-size: 14px;
    font-weight: 200;
}
.product .info p{
    font-weight: 500;
    margin-bottom: 0;
}

.wrapper-referral-codes{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.confirm-step .thin-text{
    font-style: normal;
    font-weight: 500;
}

.bg-main-color .MuiButtonBase-root.MuiButton-root.Mui-disabled{
    background: #7e7e7e;
    cursor: not-allowed;
}

.wrapper-confirm-email-already-confirm-message{
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    color: white;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.wrapper-confirm-email-already-confirm-message p{
    margin: 0;
}

.wrapper-confirm-email-already-confirm-message .MuiButton-label a{
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
}
