.switcher-option{
    display: flex;
    background: #E7E7E7;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 50px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    width: 320px;
    max-width: 100%;
    margin: 40px auto;
    justify-content: space-between;
    position: relative;
}

.wrapper-calendar-flex-box{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /*flex-grow: 1;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}

.wrapper-calendar{
    width: 100%;
}
.switcher-option .background-switcher-option-animation{
    position: absolute;
    width: 100%;
    height: 100%;
    flex-basis: 100%;
    z-index: 0;
    padding: 3px 5px;
    box-sizing: border-box;
    transition: .3s
}

.switcher-option .background-switcher-option-animation .active{
    background: #2D3340;
    border-radius: 50px;
    width: 50%;
    height: 100%;
    transition: .3s;
    transform: translate(0px, 0px);
}
.switcher-option .background-switcher-option-animation .active.right-side{
    transform: translate(100%, 0px);
}
.switcher-option>div{
    flex-basis: 50%;
    text-align: center;
    padding: 10px 0;
    z-index: 1;
    transition: .2s;
}
.switcher-option>div:not(.active){
    cursor: pointer;
}
.switcher-option>div.active{
    color:white;
}

.rbc-day-bg{
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    padding-bottom: 15px;
}

.rbc-day-bg:after{
     font-style: normal;
     font-weight: 300;
     font-size: 9px;
     position: absolute;
     bottom: 18px;
}

.wrapper-calendar.lunch .rbc-day-bg:after{
    content: attr(data-lunch);
}

.wrapper-calendar.dinner .rbc-day-bg:after{
    content: attr(data-dinner);
}

.rbc-month-header{
    padding-bottom: 14px;
    border-bottom: 1px solid #2D3340;
}

.rbc-toolbar{
    margin-left: 20px;
    font-size: 18px;
}


.guest_calendar{
    background: #F7F7F7;
    width: 100vw;
    height: calc( 100vh - 114px );
    display: flex;

    justify-content: center;
    flex-wrap: wrap;
    max-width: 500px;
    flex-direction: column;
}

.wrapper_calendar{
    width: 100%;
    background: white;
    padding: 26px 7px 26px 0;
    box-sizing: border-box;
    -webkit-box-shadow: 0px -1px 28px -2px rgba(34, 60, 80, 0.2) inset;
    -moz-box-shadow: 0px -1px 28px -2px rgba(34, 60, 80, 0.2) inset;
    box-shadow: 0px -1px 28px -2px rgba(34, 60, 80, 0.2) inset;
    margin-bottom: 60px;
}

.guest_calendar_title{
    background: #2D3340;
    height: 70px;
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #F7F7F7;
    line-height: 70px;
}
.guest_calendar .loader{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

}

.week_line{
    margin-bottom: 26px;
}
.week_line:last-child{
    margin-bottom: 0;
}
.days_of_weeks, .week_line .line{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}
.days_of_weeks{
    padding: 0 7px 15px 0;
}
.days_of_weeks > div, .week_line .line > div{
    width: 44px;
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #797979;
    cursor: pointer;
}
.week_line .line > div.current{
    background: #EDE6DD !important;
}
.week_line .line.top > div:not(.month-label){
    background: #2D3340;
    border-radius: 10px 10px 0px 0px;
    color:white;
}

.week_line .line.top > div.previous:not(.month-label){
    background: #F7F7F7 !important;
    color:#797979 !important;
}
.week_line .line.top > div.no-options:not(.month-label){
    background: #797979;
}

.week_line .line.top > div{
    height: 20px;
    line-height: 20px;
}

.line.lunch{
    border-bottom: 0.5px solid rgba(121, 121, 121, 0.2);
}

.line .month-label{
    font-weight: 600;
    font-size: 12px;
    font-family: Barlow;
    text-transform: capitalize;
    color: #2D3340 !important;
}

.line.lunch div, .line.dinner div{
    font-size: 9px;
    color: #2D3340;
    min-height: 12px;
}
.line.lunch div:not(.type), .line.dinner div:not(.type){
    padding: 7px 0;
    background:  #F7F7F7;
    font-weight: 300;
}

.line.dinner div:not(.type){
    border-radius: 0 0 10px 10px;
}

.line .type{
    font-family: Barlow !important;
    font-weight: 300 !important;
}

.wrapper_calendar .actions{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color:#797979;
    font-size: 12px;
}

.rbc-calendar{
    width: 100%;
}

.wrapper_calendar .actions > div{
    margin-right: 20px;
}

.wrapper_calendar .actions > div:last-child{
    margin-right: 0;
}

.wrapper_calendar .actions .current-date{
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper_calendar .actions .current-date .arrow{
    width: 35px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.wrapper_calendar .actions .next, .wrapper_calendar .actions .back{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #2D3340;
    color: #2D3340;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.calendar-info{
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #797979;
    text-align: center;
    width: 100%;
    margin: 15px 0;
    margin-bottom: 0;
}
.supplier-name-on-calendar{
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: rgb(247, 247, 247);
    background: rgb(45, 51, 64);
}
.prices-info-on-the-buttom{
    background: #efdabc;
    text-align: center;
    padding: 15px;
    color:#2D3340;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    position: fixed;
    bottom: 0;
    width: 500px;
    box-sizing: border-box;
    max-width: 100%;
    z-index: 15;
}

.close-price-info-button{
    position: absolute;
    right: 20px;
    top: 20px;
}
.price-info-wrapper .title{
    font-weight: 600;
    font-size: 18px;
    color: #2D3340;
    margin-bottom: 30px;
}

.price-info-wrapper{
    padding: 30px 20px;
}
.price-info-wrapper .content p{
    margin-bottom: 20px;
}
.price-info-wrapper .content{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #797979;
}

.prices-info-on-the-buttom img{
    margin-left: 5px;
}

@media (max-width: 400px) {
    .days_of_weeks > div, .week_line .line > div{
        width: 35px;
        font-size: 8px;
    }
    .rbc-day-bg:after{
        bottom: 22px;
        font-size: 8px;
    }
}

@media (max-height: 720px) {
    .supplier-name-on-calendar{
        min-height: 50px;
        font-size: 16px;
    }
    .switcher-option>div{
        padding: 7px 0;
    }
    .rbc-toolbar .rbc-toolbar-label{
        font-size: 14px;
    }
}
@media (max-height: 670px) {
   .supplier-name-on-calendar{
       min-height: 40px;
       font-size: 15px;
   }
    .rbc-month-header{
        margin: 5px 0;
    }
    .wrapper_calendar{
        padding: 15px 0 20px 0;
    }
}