.booking-is-ready-info p{
    margin: 0;
    font-size: 12px;
    margin-bottom: 10px;
    color:#797979
}

.booking-is-ready-info b{
    font-size: 14px;
    color: #2D3340;
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
}

.add-new-card{
    margin-bottom: 40px;
}

.payment-form-on-supplier-page label{
    margin-bottom: 13px;
}
body .payment-form-on-supplier-page .MuiFormControl-fullWidth{
    min-height: 65px;
}
body .payment-form-on-supplier-page .new-card-payment-form-wrapper{
    overflow: hidden;
}
body .payment-form-on-supplier-page .new-card-payment-form-wrapper .MuiInputBase-root{
    margin-bottom: 14px;
}
body .pay-button-on-supplier-page{
    margin-bottom: 30px;
}

.payment-form-on-supplier-page{
    overflow: hidden;
}