.register-subscription .bg-main-color .top{
    text-align: center;
    margin: 30px 0;
}
.register-subscription .bg-main-color .top h2{
    font-size: 25px;
}

.register-subscription .bg-main-color .top p{
    font-size: 16px;
}

.register-subscription .bg-main-color.bottom{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.register-subscription .bg-main-color.bottom p{
    margin: 15px 0;
}

.conform-email-screen p{
    font-weight: bold;
    max-width: 80%;
    text-align: center;
}

.conform-email-screen-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
}

.logo-on-register-page{
    width: 150px;
}

.register-notifications{
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    z-index: 1;
    top:0;
    color:white;
    padding: 15px;
    text-align: center;
    font-size: 16px;
    box-sizing: border-box;
    font-family: Barlow;
    font-weight: 600;
    max-width: 500px;
    width: 100%;
    margin: auto;
}

.back-to-login{
    margin-bottom: 20px;
}