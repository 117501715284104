.wrapper-confirm-email-already-confirm-message{
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    color: white;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.wrapper-confirm-email-already-confirm-message p{
    margin: 0;
}

.wrapper-confirm-email-already-confirm-message .MuiButton-label a{
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
}