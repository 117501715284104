.sep{
    width: calc(100% + 30px);
    margin: 20px -15px 10px;
    height: 1px;
    background: #2D3340;
}

.sup-header {
    position: relative;
}
.date{
    font-weight: 600;
    font-size: 20px;
}
.close-day{
    position: absolute !important;
    color: white !important;
    left: 10px;
}
.services .optionBlock{
    background: #F7F7F7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.services .optionBlock:last-child .line{
    border-bottom: 0 !important;
}

.blackButton.small-btn.blue{
    background: #2D3340;
}

.rbc-day-bg[notifications]:before{
    content: attr(notifications);
    width: 12px;
    height: 12px;
    line-height: 12px;
    background: #bb3636;
    color:white;
    border-radius: 50%;
    position: absolute;
    text-align: center;
    font-size:9px;
    margin-top: -15px;
    margin-right:-15px;
}

.booking-status-checkbox{
    margin: -20px;
    padding: 12px 20px;
    background: #2D3340;
    color: white;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px 15px 0 0;
}
.booking-status-checkbox .small-btn{
    padding: 3px 15px !important;
}
.booking-status-checkbox .accept-btn{
    border: 1px solid white;
}
.booking-status-checkbox .MuiFormControlLabel-labelPlacementStart{
    margin: 0;
}
.booking-status-checkbox .MuiCheckbox-root .MuiSvgIcon-root{
    color:white;
}

.booking-status-checkbox .label{
    font-weight: 500;
    font-size: 18px;
}

.booking-status-checkbox .MuiTypography-root{
    font-size: 12px;
}