.main-logo{
    margin-right: 20px;
}

.ourSectionSlider .react-swipeable-view-container > div:last-child > div{
    height: calc(100% - 55px);
}

.searchResultWrapper a{
    margin-bottom: 10px;
    margin-top: 30px;
    position: relative;
}
.price_from{
    position: absolute;
    right: 0;
    font-weight: bold;
    background: rgba(255,255,255, .92);
    padding: 5px 15px;
    transform: translate(0, -111%);
    font-size: 14px;
}
.price_from span{
    font-weight: normal;
}
.searchResultWrapper h3{
    margin-top: 10px;
}
.searchResultWrapper a p{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #2D3340;
    margin-top: 14px;
}

.searchResultWrapper .short_description{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #797979;
    display: flex;
}
.searchResultWrapper .short_description .content{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchResultWrapper .tags{
    display: flex;
    margin-top: 9px;
    flex-wrap: wrap;
}

.searchResultWrapper .tags div{
    padding: 0 10px;
    height: 23px;
    background: #797979;
    opacity: 0.4;
    color:white;
    border: 1px solid #797979;
    box-sizing: border-box;
    border-radius: 12px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.searchResultWrapper .moreless{
    color: #2D3340;
    text-decoration: underline;
    cursor: pointer;
}

.short_description.show-all{
    display: block;
}
.short_description.show-all .content{
    white-space: break-spaces;
}

.start-icon{

}