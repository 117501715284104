.wrapper-pay-entrance-fees{
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    overflow: scroll;
}

.wrapper-pay-entrance-fees.payment-step{
    justify-content: unset;
}

.wrapper-pay-entrance-fees img{
    width: 40px;
}

.wrapper-pay-entrance-fees .title{
    color:white;
    font-family: Barlow;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    margin-top: 25px;
}

.wrapper-pay-entrance-fees .title p{
    font-size: 18px;
    margin-top: 0;
}

.top-block{
    text-align: center;
    padding-bottom: 40px;
    width: 100%;
    border-bottom: 0.5px solid white;
}

.wrapper-pay-entrance-fees .content{
    width: 100%;
}

.wrapper-pay-entrance-fees.payment-step .content{
    flex-grow: 1;
}
.wrapper-pay-entrance-fees.payment-step .top-block{
    margin-top: 20px;
}

.payment-wrapper-entrance-fees{
    display: flex;
    flex-wrap: wrap;
}


.wrapper-pay-entrance-fees .price-line{
    color:white;
    font-family: Barlow;
    font-weight: 600;
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.wrapper-pay-entrance-fees .price-wrapper{
    display: flex;
}

.wrapper-pay-entrance-fees .price-wrapper .price{
    margin-left: 25px;
}

.wrapper-pay-entrance-fees .price-wrapper .old-price{
    text-decoration: line-through;
    font-weight: normal;
}

.referral-code-form{
    margin-top: 60px;
    color:white;
}


.referral-code-form .MuiOutlinedInput-root, .wrapper-pay-entrance-fees-form .MuiOutlinedInput-root{
    background: rgba(247, 247, 247, 0.3);
    color:white;
    border-radius: 12px;
}

.referral-code-form .label{
    margin-bottom: 10px;
}
.referral-code-form .wrapper-input{
    display: flex;
}

.btn-referral-submit{
    cursor: pointer;
    border: 2px solid #F7F7F7;
    box-sizing: border-box;
    border-radius: 12px;
    margin-left: 12px;
    text-align: center;
    width: 62px;
    display: flex;
    justify-content: center;
    height: 45px;
}

.btn-referral-submit img{
    width: 22px;
}

.success-message{
    color: white;
    margin-top: 10px;
}

.wrapper-pay-entrance-fees-form{
    color:white;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.wrapper-pay-entrance-fees-form .MuiOutlinedInput-root{
    margin-bottom: 10px;
}

.wrapper-pay-entrance-fees .MuiOutlinedInput-notchedOutline{
    border-color: white !important;
}

.wrapper-pay-entrance-fees-form .price-line{
    margin-bottom: 50px;
}

.wrapper-pay-entrance-fees-form .title{
    margin: 0;
    font-size: 18px;
}


.wrapper-pay-entrance-fees-form .MuiCheckbox-root .MuiSvgIcon-root{
    color:white;
}

.confirm-step .text-content{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.confirm-step .text-content p{
    margin: 0;
    margin-bottom: 10px;
}

.confirm-step .product{
    background: none;
    border: 2px solid white;
    padding: 20px;
    width: 100%;
}

.old-price{
    text-align: right;
}

.old-price span{
    text-decoration: line-through;
}
.confirm-step{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
}
.product .info{
    font-size: 14px;
    font-weight: 200;
}
.product .info p{
    font-weight: 500;
    margin-bottom: 0;
}

.wrapper-referral-codes{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.confirm-step .thin-text{
    font-style: normal;
    font-weight: 500;
}

.bg-main-color .MuiButtonBase-root.MuiButton-root.Mui-disabled{
    background: #7e7e7e;
    cursor: not-allowed;
}