.chat-attachments{
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
}

.chat-attachments .attachment{
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
}

.chat-attachments .attachment .MuiCircularProgress-colorPrimary{
    position: absolute;
    top: 0;
    z-index: 222222;
    color: white;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.remove-attachment{
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    /*font-size: 7px;*/
    width: 15px;
    line-height: 10px;
    text-align: center;
    height: 15px;
    right: 2px;
    top:2px;
    background: rgba(255,255,255,.5);
}

.chat-attachments .attachment img{
    max-width: 200%
}