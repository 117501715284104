
.product{
    position: relative;
    padding: 23px 23px 23px 5px;
    margin-top: 30px;
    background: #495468;
    border: 2px dashed #495468;
    box-sizing: border-box;
    border-radius: 12px;
    color:white;
    cursor: pointer;
}


.product .content{
    display: flex;
    width: 100%;
}

.product .content > div:last-child{
    flex-grow: 1;
}

.product .content .top{
    display: flex;
    justify-content: space-between;
}

.product .MuiRadio-colorSecondary.Mui-checked{
    color:white;
}

.product .MuiRadio-root{
    color:white;
}

.product .MuiInput-underline:before{
    border-color:white;
}

.product .MuiInput-underline:hover:not(.Mui-disabled):before, .product .MuiInput-underline:focus:not(.Mui-disabled):before{
    border-color:white;
}
.product .MuiSelect-icon{
    fill:white;
}

.product .MuiSelect-select.MuiSelect-select{
    color:white;
}

.product .MuiInputLabel-formControl{
    color: white !important;
    font-size: 14px;
    font-weight: normal;
    padding-top: 7px;
}

.product.disabled:after{
    content:"";
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0,0,0,.3);
    z-index: 11;
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.product .product-name{
    font-size: 20px;
    font-weight: 600;
}
.product .info{
    margin-top: 10px;
    font-size: 14px;
    font-weight: normal;
}

.product .product-price{
    font-size: 18px;
    font-weight: 600;
}

.product .product-price small{
    font-size: 10px;
    font-weight: 400;
}

.soon{
    display: none;
    position: absolute;
    left: -1px;
    top: -1px;
    background: #AEB3BF;
    color:white;
    z-index: 12;
    padding: 7px;
    border-radius: 7px 0 7px 0;
}


.disabled .soon{
    display: block;
}

.plans-from-profile .product.disabled{
    background: white;
    color: #AEB3BF
}

.plans-from-profile .product.disabled:after{
    background: rgba(0,0,0,.03);
}

.plans-from-profile .bottom{
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}