.App {
  text-align: center;
}
.tel-number {
  margin: 20px 0;
  display: block;
  text-align: center;
}

.login-page .tel-number{
  margin: 30px 0 20px 0;
}

.tel-number a{
  color: #091026 !important;
}
.bg-main-color .tel-number a{
  color: white !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.multiline-input > div{
  height: auto;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.share-block{
  padding: 10px;
  background: #F7F7F7;
  font-weight: bold;
  display: flex;
  border-radius: 15px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.share-block img{
  width: 18px;
  margin-right: 3px;
}
.share-desktop{
  position: absolute;
  right: 0;
  transform: translate(0, 72%);
  padding: 10px;
  font-size: 12px;
  font-weight: normal;
  border: 1px solid #eee;
  min-width: 250px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  overflow: hidden;
}
.close-share{
  margin: 0;
  margin-bottom: 3px;
  float: right;
  padding: 10px;
  text-align: right;
  font-size: 16px;
  padding-right: 0;
  padding-top: 0;
}
#share-input{
  position: absolute;
  transform: translate(-1000%,0);
}
.share-desktop .share-img{
  width: 13px;
  margin-right: 7px;
}
.green-text{
  color:darkgreen;
}
.share-desktop div{
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.share-desktop a{
  line-height: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color:rgba(0,0,0,0.87)

}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

html[class^="iPhone13,"]{
  margin-top: 44pt;
  background: white !important;
}
html[class^="iPhone13,"] .MuiDialog-paperFullScreen{
  margin-top: 50pt !important;
}

html[class^="iPhone12,"]{
  margin-top: 29pt;
  background: white !important;
}
html[class^="iPhone12,"] .MuiDialog-paperFullScreen{
  margin-top: 50pt !important;
}

html[class^="iPhone10,"]{
  margin-top: 17pt;
  background: white !important;
}
html[class^="iPhone10,"] .MuiDialog-paperFullScreen{
  margin-top: 34pt !important;
}


.premium b{
  font-weight: 600;
  font-size: 18px;
}

.premium .plan{
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 15px;
  box-sizing: border-box;
  border: 0.5px solid #797979;
  border-radius: 12px;
}
.premium .plan img{
  margin-right: 3px;
}
.premium .plan.black{
  background: #495468;
  border: 0.5px solid #333E56;
  color:white;
}
.premium .plan.black .info{
  color:white;
}

.premium .plan .title{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.premium .plan .title b{
  display: inline;
}
.premium .plan .title .text{
  font-weight: 600;
  font-size: 20px;
}

.premium .plan .info{
  font-size: 14px;
  line-height: 17px;
  color: #797979;
}

.premium .actions{
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.premium .actions div{
  min-width: 180px;
  padding: 6px;
  font-weight: 600;
  border-radius: 13px;
  border: 1px solid white;
  text-align: center;
  cursor: pointer;
}

.subscription-title{
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 10px;
}

.subscription-payment{
  padding: 20px;
}

.subscribe-payment-dialog .searchHeader{
  justify-content: flex-end;
}

.subscription-payment hr{
  margin: 20px 0;
}

.total-subscription{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.commission_plan{
  display: flex;
  padding: 13px;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #797979;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 16px;
}
.commission_plan b{
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
}
.commission_plan span{
  font-weight: normal;
  font-size: 14px;
  color: #797979
}

.commission_plan.premium{
  background: #495468;
  border-color: #79808D;
  color:white;
}

.commission_plan.premium span{
  color:white;
}

.commission_plan.premium span.old-price{
  text-decoration: line-through;
  margin-right: 5px;
}

.upgrade-plan{
  text-align: center;
  margin: 12px;
  text-decoration: underline;
  display: block;
  cursor: pointer;
}

.subscription-info-for-booking .info{
  display: flex;
  justify-content: space-between;
}

.subscription-info-for-booking .dots{
  flex-grow: 1;
  border-bottom: 0.5px dashed #ABABAB;
  align-items: center;
  margin: 0 5px 2px;
}

.subscription-info-for-booking img{
  margin-right: 5px;
}
.subscription-info-for-booking b{
  margin-right: 5px;
}

.subscription-info-for-booking .price{
  display: flex;
}

.subscription-info-for-booking .price .old-price{
  text-decoration: line-through;
  margin-right: 10px;
}

.address.select-card-on-subscribe .left .subtitle{
  display: flex;
  font-weight: normal;
  align-items: center;
  font-size: 15px;
}

.address.select-card-on-subscribe .left .date{
  font-weight: normal;
  font-size: 15px;
}

.address.select-card-on-subscribe .left .subtitle div:first-child{
  display: flex;
  align-items: center;
}
.address.select-card-on-subscribe .left .subtitle img{
  margin-right: 5px;
}

.address.select-card-on-subscribe{
  font-weight: normal;
  font-family: Barlow;
  color: #838788;
}

.address.select-card-on-subscribe .right{
  background: none;
}

.subscribe-extra-info{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  color: #797979;
  height: 20vh;
}

.newMessageCounter{
  position: absolute;
  width: 16px;
  height: 16px;
  background: #bb3636;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  color:white;
  font-size: 11px;
  margin-left: -5px;
}
.subscribe-extra-info a{
  text-decoration: underline;
}

.text-center{
  text-align: center;
}