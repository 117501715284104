.global-notification-block{
    position: absolute;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    bottom: 54px;
}

.notification{
    display: flex;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.notification img{
    margin-right: 10px;
}

.notification.message{
    background: #f6e2c4;
    color: #E29D34;
}

.notification-content{
    display: flex;
    align-items: center;
}

.notification.booking-suggest{
    background: #D7E1F8;
}