.topbar-booking-popup{
    min-height: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: rgb(247, 247, 247);
    background: rgb(45, 51, 64);
    font-weight: 600;
}

.back-to-search{
    background: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    left: 15px;
}

.supplier-wrapper {
    position: relative;
}

.changeDateWrapper{
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 0 15px 0 15px;
}

.changeDateWrapper .MuiSelect-outlined.MuiSelect-outlined{
    color: #797979;

}
.changeDateWrapper .MuiOutlinedInput-notchedOutline{
    border: 1px solid #2D3340 !important;
}
.changeDateWrapper > span{
    font-size:14px;
    font-weight: 500;
    color: #797979;
}
.back-to-search svg{
    margin-left: 8px;
}

.sliderDots{
    width: 100vw;
    position: absolute;
    margin-top: -35px;
    justify-content: center;
    display: flex;
}

.sliderDots > div{
    background: rgba(0, 0, 0, 0.7);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    -webkit-box-shadow: 1px 39px 25px -23px rgba(34, 60, 80, 0.16);
    -moz-box-shadow: 1px 39px 25px -23px rgba(34, 60, 80, 0.16);
    box-shadow: 1px 39px 25px -23px rgba(34, 60, 80, 0.16);
    margin-right: 8px;
}
.sliderDots > div:last-child{
    margin-right: 0;
}

.sliderDots > div.active{
    background: #FFFFFF;
}

.lastMinuteBookingWrapper{
    background: #F7F7F7;
    padding: 12px;

}

.lastMinuteBookingWrapper .title{
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #797979;
    padding-bottom: 15px;
}

.lastMinuteBookingWrapper .title img{
    margin-right: 12px;
}

.lastMinuteBookingWrapper .content{
    margin-top: 20px;
}
.lastMinuteBookingWrapper .content p{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #797979;
    margin: 0;
}

.lastMinuteBookingWrapper .content .line{
    display: flex;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2D3340;
}

.lastMinuteBookingWrapper .content .sep{
    flex-grow: 1;
    border-bottom: 0.2px dashed #797979;
    background: none;
    margin: 13px 7px;
    width: auto;
}

.minimum-spending{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #2D3340;
    margin-bottom: 20px;
}

.commission_plan p{
    margin: 0;
}

.supplier-wrapper .switcher-option{
    margin: 20px auto;
}

.wrapperBooking.booking-on-supplier-page{
    background: #F7F7F7;
    box-shadow: 4px 4px 12px rgba(9, 16, 38, 0.15);
    border-radius: 12px;
    padding: 24px;
}

.wrapperBooking.booking-on-supplier-page .generalInfo{
    background: none;
    padding: 0;
    border-bottom: 1px solid #2D3340;
    padding-bottom: 30px;
    border-radius: 0;
}

.wrapperBooking.booking-on-supplier-page .line{
    display: flex;
    justify-content: space-between;
}

.wrapperBooking.booking-on-supplier-page .line .title{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #2D3340;
    text-align: left;
}
.wrapperBooking.booking-on-supplier-page .line:last-child{
    color: #2D3340;
}

.wrapperBooking.booking-on-supplier-page .line .subtitle,  .wrapperBooking.booking-on-supplier-page .small{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    color: #797979;
    font-size: 12px;
    margin: 0;
}

.wrapperBooking.booking-on-supplier-page .blockInfo{
    padding: 0;
    margin-top: 30px;
    padding-bottom: 30px;
}

.wrapperBooking.booking-on-supplier-page .canceling-condition-block .line{
    margin-bottom: 10px;
}


.wrapperBooking.booking-on-supplier-page .canceling-condition-block .justify-between{
    margin-bottom: 15px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
}

.wrapperBooking.booking-on-supplier-page .blockInfo.canceling-condition-block{
    padding-bottom: 0;
}

.close-booking-modal-first-step{
    position: absolute;
    right: 10px;
    font-size: 25px;
    top: 10px;
    padding: 10px;
}

.confirm-booking-button{
    margin-bottom: 40px;
}
.booking-first-step-popup{
    margin-bottom: 40px;
    padding: 0 10px;
}
.booking-first-step-popup label{
    font-size: 20px;
    margin-bottom: 20px;
    color:#2D3340;
}
.booking-first-step-popup .MuiInputBase-formControl{
    margin-bottom: 20px;
}
.first-step-booking-dialog-wrapper .MuiDialog-paperScrollPaper{
    justify-content: end;
}

.optionBlock .title{
    margin-bottom: 13px;
}

.optionBlock .line{
    color: #797979
}

.extraSupplierInfo .title{
    margin-top: 0;
}